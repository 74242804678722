<template>
    <div class="noise-container">
        <label v-for="(noiseTypeLabel, noiseType) in noiseTypes" :key="noiseType">
            <input type="radio" :value="noiseType" v-model="noiseTypeModel"/>
            {{ noiseTypeLabel }}
        </label>
    </div>

</template>

<style lang="scss" scoped>
    .noise-container {
        display: inline-block;

    }

    label {
        display: block;
        margin: 1em;
        text-align: left;
    }
</style>

<script>
    import CtxMix from '../MixinsCtx';

    const BUFFER_SIZE = 16384;

    export default {
        mixins: [ CtxMix ],
        props: {
            params: [Object, String],
        },

        data(){
            return {
                noiseTypes: {
                    off: 'Off',
                    white: 'Белый шум',
                    pink: 'Розовый шум',
                    brown: 'Броуновский (красный, «коричневый») шум',
                }
            }
        },

        computed: {
            noiseTypeModel : {
                get(){
                    const type = this.params ? this.params : '';

                    switch (type){
                        case 'white':
                            this.setGains([ this.ctx.createWhiteNoise( BUFFER_SIZE ) ]);
                            break;

                        case 'pink':
                            this.setGains([ this.ctx.createPinkNoise( BUFFER_SIZE ) ]);
                            break;

                        case 'brown':
                            this.setGains([ this.ctx.createBrownNoise( BUFFER_SIZE ) ]);
                            break;

                        default:
                            this.setGains([ ]);
                    }

                    return type;
                },

                set(newType){
                    this.$emit('params', newType);
                }
            }
        }
    }
</script>