import { render, staticRenderFns } from "./LocalFiles.vue?vue&type=template&id=8f40f916&scoped=true&"
import script from "./LocalFiles.vue?vue&type=script&lang=js&"
export * from "./LocalFiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f40f916",
  null
  
)

export default component.exports