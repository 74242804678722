<template>
    <div id="app">
        <audio-source class=""
                :ctx="ctx"
                :destination="gains[0]"
                :config="config.s"
                @config="(sourceConfig) => { config.setParams('s', sourceConfig) }"
        ></audio-source>

        <add-element :availableComponents="availableComponents"
                     @insert="(component) => { addModule(component, 0) }"
        ></add-element>

        <template v-for="(element, num) in modules">

            <component :is="element.t"
                       :key="num"
                       :ctx="ctx"
                       :destination="getGain(num + 1)"
                       :params="element.p"
                       @set_params="(params) => { config.setParams(num, params) }"
                       @input_gains="(gains)=>{setGain(num,gains)}"
                       @close="closeModule(num)"
                       @capture_resource = "captureResource"
            ></component>
        </template>

        <add-element v-if="isLastAded"
                     :availableComponents="availableComponents"
                     @insert="(component) => { addModule(component, modules.length) }"
        ></add-element>
    </div>
</template>

<style lang="scss" scoped>
    #app {
        text-align: center;

        max-width: 940px;
        margin: 1em auto;
    }
    .file-list {
        display: inline-block;
    }

</style>

<script>
    import './AudioContext/Noise';
    import Equalizer from './AudioContext/Equalizer';
    import SpectrAnalyzer from './AudioContext/spectrAnalyzer';
    // import SpectrAnalyzer2 from './AudioContext/spectrAnalyzer2';
    import Osciloscop from './AudioContext/Osciloscop';
    import AudioSource from './AudioContext/Source';

    import Destination from './AudioContext/Destination';
    import DestMic from './AudioContext/DestMic';
    import Headset from './AudioContext/Headset';
    import Boost from './AudioContext/Boost';
    import Harmony from './AudioContext/2Harmony';
//    import Convolver from './AudioContext/Convolver';
    import ImpulseNoise from './AudioContext/ImpulseNoise';

    import AddElement from './components/AddElement';

    export const type = 'App';

    const components = {
        Equalizer,
        SpectrAnalyzer,
        // SpectrAnalyzer2,
        Osciloscop,
        Destination,
        DestMic,
        Headset,
        Boost,
        Harmony,
        ImpulseNoise
        //Convolver,
    };

    export default {
        components: {...components, AudioSource, AddElement},

        type,

        props: {
            config: Object,
        },

        data() {
            const AudioContext = window.AudioContext || window.webkitAudioContext;
            const ctx = new AudioContext();

            // console.log('data', components);
            this.type = type;

            return {
                ctx,
                gains: [[]],

                allComponents: components,

                resources: { },
            }
        },

        computed: {
            modules(){
                // while (this.config.m.length >= this.gains.length){
                //     this.gains.push([]);
                // }

                return this.config.m;
            },

            isLastAded(){
                if(this.config.m.length == 0){
                    return false;
                }

                // console.log('isLastAded', Object.assign({}, this.config.m[this.config.m.length - 1]));
                return this.config.m[this.config.m.length - 1].t != 'Destination';
            },

            availableComponents(){
                // console.log('allComponents', this.allComponents);

                return Object.keys(this.allComponents).filter((compName) => {

                    const used = this.modules.filter((module) => (module.t == compName)).length;
                    return this.allComponents[compName].maxInstances > used;

                }).reduce((result, compName) => {
                    result[compName] = this.allComponents[compName];
                    return result;
                }, {});
            },
        },

        methods: {
            setGain(index, input_gains) {
                console.log('set gains for ' + index, input_gains);
                while (this.gains.length <= index){
                    this.gains.push([]);
                }
                this.gains[index] = input_gains;
                console.log('set. gains', [ ...this.gains ]);
                this.gains = [ ...this.gains ];
            },

            getGain(index){
                while (this.config.m.length >= this.gains.length){
                    this.gains.push([]);
                }

                return this.gains[index];
            },

            getDestination(index) {
                if ((index + 1 >= 0) && (index <= this.gains.length - 2)) {
                    // console.log('destination for ' + index, this.gains[index + 1]);
                    return this.gains[index + 1];
                } else {
                    // console.log('destination for ' + index + " empty");
                    return [];
                }
            },

            closeModule(num){
                this.config.removeModule(num);
                console.log('closeModule', [ ...this.gains ]);
                this.gains.splice(num,1);
                console.log('closeModule2', [ ...this.gains ]);
                this.refreshGains();
            },

            addModule(component, num){
                console.log('addModule', [ ...this.gains ]);
                this.gains.splice(num, 0, [ ]);
                this.config.addModule(num, component, null);
                this.refreshGains();
            },

            refreshGains(){
                this.$nextTick(() => {
                    console.log('refreshGains');

                    this.$children.forEach((module) => {
                        if (module.gainsIn) {
                            module.$emit('input_gains', module.gainsIn);
                        }
                    });
                });
            },

            captureResource(resources){
                Object.keys(resources).forEach((resourceName) => {
                    if(typeof this.resources[resourceName] == 'function'){
                        this.resources[resourceName]();
                    }

                    this.resources[resourceName] = resources[resourceName];
                });
            }
        },


        mounted() {
            // const self = this;

            setTimeout(() => {
            }, 3000);

            setInterval(() => {
//                console.log('interval tic', this);
            }, 10000)
        },
    }
</script>
