export const RESOURCE_MIC = function (cb) {
    return {
        mic: cb,
    }
};
export const RESOURCE_DEST = function (cb) {
    return {
        dest: cb,
    }
};
