<template>
    <element-container class="module-container" v-on="$listeners">
        <button @click="toggleOut">
            <font-awesome-icon icon="volume-up" size="4x" class="icon device-on" v-if="outEnable" />
            <font-awesome-icon icon="volume-off" size="4x" class="icon device-off" v-else />
        </button>

        <button @click="toggleMic">
            <font-awesome-icon icon="microphone" size="4x"
                               class="icon" :class="microphoneEnable ? 'device-on' : 'device-off'" />
        </button>
    </element-container>


</template>

<style lang="scss" scoped>
    @import "../common";

    .device-off {
        color: #ec0000;
    }

    .device-on {
        color: #00b100;
    }

    button {
        @include button;
    }

    .icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>

<script>

    import MixinsCtx from './MixinsCtx';
    import ElementContainer from '../components/smallElementContainer';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {faMicrophone, faMicrophoneAlt, faTimes, faVolumeOff, faVolumeUp} from '@fortawesome/free-solid-svg-icons'

    library.add( faVolumeUp, faVolumeOff, faMicrophone, faMicrophoneAlt, faTimes );

    import { RESOURCE_DEST /*, RESOURCE_MIC */ } from '../resourceTypes';

    export default {
        mixins: [ MixinsCtx ],
        maxInstances: 1,

        components: { FontAwesomeIcon, ElementContainer },

        props: {
            params: [Object]
        },

        data(){
            return {
                stream: null,
            }
        },

        computed: {
            outEnable(){
                console.log('outEnable', this.params);

                const params = Object.assign({ o: 0 }, this.params);
                const result = params.o > 0;
                this.setGainsIn( result ? [ this.ctx.destination ] : [ ]);
                return result;
            },

            microphoneEnable(){
                console.log('microphoneEnable', this.params);
                const params = Object.assign({ m: 0 }, this.params);
                const result = params.m > 0;

                if(result) {
                    if (!this.stream) {
                        this.startInput();
                    }
                    this.stream.start();
                }else {
                    if(this.stream){
                        this.stream.stop();
                        // this.stream = null;
                        this.setGainsOut([ ]);
                    }
                }

                return result;
            }
        },

        methods: {
            setNewConfig(config){
                this.$emit('set_params', Object.assign({
                    m: this.microphoneEnable ? 1 : 0,
                    o: this.outEnable ? 1 : 0,
                }, config));
            },

            setOutOn(){
                this.$emit('capture_resource',RESOURCE_DEST( function () {
                    this.setNewConfig({ o: 0 });
                }.bind(this) ));

                this.setNewConfig({ o: 1 });
            },

            setOutOff(){
                this.setNewConfig({ o: 0 });
            },

            toggleOut() {
                if (this.outEnable) {
                    this.setOutOff();
                } else {
                    this.setOutOn();
                }
            },

            setMicOn(){
                this.$emit('capture_resource',RESOURCE_DEST( function () {
                    this.setNewConfig({ m: 0 });
                }.bind(this) ));

                this.setNewConfig({ m: 1 });
            },

            setMicOff(){
                this.setNewConfig({ m: 0 });
            },

            toggleMic() {
                if (this.microphoneEnable) {
                    this.setMicOff()
                } else {
                    this.setMicOn();
                }
            },

            startInput(){
                const self = this;

                const getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

                console.log('getUserMedia', getUserMedia);
                getUserMedia.call( navigator,
                    { audio: true, video: false },
                    function (stream) {
                        const source = self.ctx.createMediaStreamSource(stream);
                        self.setGainsOut([ source ]);
                        console.log('stream', stream);
                        self.stream = stream;
                        //stream processing
                    },
                    function (error) {
                        console.log(error);
                        self.toggleMic();
                        //error processing
                    }
                );
            }
        },

        mounted(){
            this.setOutOn();
        },

        beforeDestroy(){
            if(this.stream){
                this.stream.stop();
            }
        }
    }
</script>
