/** @see https://github.com/nodeca/pako */
// const pako = require('pako');

export default (router,config_cb) => {

    const Config = function (params) {
        Object.keys(params).forEach((key) => {
            this[key] = params[key];
        });
    };

    let currConfig = {
        s: JSON.stringify({ t: 'player', p: null }), // s{ource}: { t{ype}: ___, p{arams}: ___ }
        m: [
            JSON.stringify({ t: "Osciloscop", p: 1 }),
            JSON.stringify({ t: "Destination", p: false }),
        ],   // m{odules}: [ JSON.stringify({ t{ype}: ___, p{arams}: ___ }), JSON.stringify({ ... }), ... ]
    };
    let oldModuleNum = null;

    function encodeQuery(data) {
        const query = { s: data.s };
        data.m.forEach((item,key) => {
            query['m' + key] = item;
        });

        return query;
    }

    function decodeQuery(query) {
        const data = {  };

        if(query.s) {
            data.s = query.s;
        }

        Object.keys(query).forEach((paramName) => {
            const test = /^m(\d+)/.exec(paramName);
            if(test){
                const index = Math.round(test[1]);
                if(!Array.isArray(data.m)){
                    data.m = [ ];
                }

                while(data.m.length <= index){
                    data.m.push(JSON.stringify({ t: "_", p: null }));
                }

                data.m[index] = query[paramName];
            }
        });

        return data;
    }

    Config.prototype.setParams = function (moduleNum, params) {
        // console.log('setParams', JSON.stringify({ moduleNum, params, currConfig }));

        if( moduleNum == 's' ){
            currConfig.s = JSON.stringify(params);
            router.push({ query: currConfig });
        }
        else if( typeof moduleNum == 'number'){
            if( moduleNum > currConfig.m.length - 1) {
                throw new Error( 'Set params for module: ' + moduleNum + ". Modules count: " + currConfig.m.length);
            }

            const currModuleConfig = JSON.parse( currConfig.m[moduleNum] );
            currModuleConfig.p = params;
            currConfig.m[moduleNum] = JSON.stringify( currModuleConfig );

            if(oldModuleNum === moduleNum){
                // console.log('replace Module Params', JSON.stringify( currConfig ));
                router.replace({ query: encodeQuery(currConfig), hash: router.currentRoute.hash });
            }else {
                // console.log('push Module Params', encodeQuery( currConfig ));
                // console.log('currentRoute', router.currentRoute);
                router.push({ query: encodeQuery(currConfig), hash: router.currentRoute.hash });
                oldModuleNum = moduleNum;
            }
        }
    };

    Config.prototype.addModule = function (num, type, params) {
        currConfig.m.splice(num, 0, JSON.stringify({ t: type, p: params}));
        oldModuleNum = num;
        router.push({ query: encodeQuery(currConfig) });
    };

    Config.prototype.removeModule = function (num) {
        currConfig.m.splice(num, 1);
        oldModuleNum = null;
        router.push({query: encodeQuery(currConfig)});
    };

    function applyRoute(route) {
        currConfig = Object.assign( currConfig, decodeQuery( route.query ));
        console.log('applyRoutePre', currConfig, decodeQuery( route.query ));
        if((!Array.isArray(currConfig.m)) && (currConfig.m)){
            currConfig.m = [ currConfig.m ];
        }

        config_cb( new Config({
            s: JSON.parse(currConfig.s),
            m: currConfig.m.map((item) => (JSON.parse(item))),
        }));
    }

    router.afterEach(function(to) {
        applyRoute(to);
    });

    applyRoute(router.currentRoute);

    return new Config({
        s: JSON.parse(currConfig.s),
        m: currConfig.m.map((item) => (JSON.parse(item))),
    });
}
