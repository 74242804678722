<template>
    <div class="button-container" v-if="!opened">
        <button @click="opened=!opened">
            <font-awesome-icon icon="plus" size="2x"></font-awesome-icon>
        </button>
    </div>

    <element-container v-else @close="opened=!opened">
        <button class="add-element__element"
                v-for="(component, key) in aComponents" :key="key"
                @click="add(component.component)">
            <span v-for="(icon, key_icon) in component.icons" :key="key_icon">
                <font-awesome-icon  :icon="icon" size="2x"></font-awesome-icon>
            </span>
            <div>{{ component.label}}</div>
        </button>

    </element-container>

</template>

<style lang="scss" scoped>
    @import "../common";

    button {
        @include button;

        vertical-align: middle;

        & > * {
            color: #555;
        }

        & > span {
            display: inline-block;
            padding: 2px;
            margin: 2px;
        }

        & > div {
            text-align: center;
        }
    }

    .add-element__element {
        margin: .5em 1em;
    }
</style>

<script>
    import ElementContainer from './ElementContainer';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {faMicrochip, faPlus, /* faVolumeUp, */ faChartBar, faHSquare, faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons'

    library.add( faPlus, faMicrochip, faChartBar, faHSquare, faMicrophoneAlt );

    const componentIcons = {
        Destination: ['volume-up'],
        Osciloscop: [ 'h-square' ],
        DestMic: [ 'volume-up', 'microphone-alt'],
    };
    const defaultIcon = 'microchip';

    const componentLabels = {
        Destination: 'Выход',
        Osciloscop: 'осцилограф',
        SpectrAnalyzer: 'Анализатор спектра',
        DestMic: 'Выход и микрофон',
        Equalizer: 'эквалайзер',
        Harmony: '2-я гармоника',
        Convolver: 'БИХ фильтр',
        ImpulseNoise: "подавитель имп. помех"
    };

    export default {
        components: { ElementContainer, FontAwesomeIcon },

        data(){
            return {
                opened: false,
            }
        },

        props: {
            availableComponents: Object,
        },

        computed: {
            aComponents(){
                console.log('addAvailableComponents', this.availableComponents);
                const result = Object.keys(this.availableComponents).map((component) => ({
                    component: component,
                    icons: (componentIcons[component] ? componentIcons[component] : [defaultIcon]),
                    label: (componentLabels[component] ? componentLabels[component] : component),
                }));

                console.log('components', result);

                return result;
            }
        },

        methods: {
            add(component){
                this.$emit('insert', component);
                this.opened = false;
            }
        }
    }
</script>
