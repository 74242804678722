<template>
    <div ref="mainContainer" :class="containerClass">
        <form>
            <label>Выберите файлы
                <input type="file" id="fileElem" multiple accept="audio/*" @change="handleFiles">
            </label>
            Или перетащите файлы сюда
            <br />
            <slot></slot>
        </form>

        <audio :src="''"></audio>
    </div>
</template>

<style lang="scss" scoped>
    input[type="file"] {
        display: none;
    }
    label {
        display: inline-block;
        background-color: #eee;
        border: 1px solid #333;
        border-radius: .5em;
        padding: 1em;
    }

    div {
        border: dashed 2px #333;
        padding: 1em 2em;

        &.bg-shadow {
            background-color: #eee;
        }
    }
</style>

<script>

    /**
     * Checking event occurred in object screen coordinates
     *
     * @param event
     * @param object
     * @return boolean
     */
    function eventOnElement (event, object) {
        let rect = object.getBoundingClientRect();

        console.log("event", event);
        console.log("rect", rect);
        return event.x >= rect.left
            && event.y >= rect.top
            && event.x <= rect.right
            && event.y <= rect.bottom;
    }

    export default {

        data(){
            return {
                isEnter: false
            }
        },

        methods: {
            handleFiles(e){
                console.log('change file', e.target.files);

                if(!(e.target.files instanceof FileList)){
                    return;
                }

                for(let i=0; i < e.target.files.length; i++){
                    this.$emit("add_file", e.target.files[i]);
                }
            },

            /**
             * Drag over component
             *
             * @param {Event} e
             */
            onDrop (e) {
                e.stopPropagation();
                e.preventDefault();

                this.isEnter = false;
                if (!e.dataTransfer.files || !eventOnElement(e, this.$refs.mainContainer)) {
                    return;
                }

                if(!(e.dataTransfer.files instanceof FileList)){
                    return;
                }

                for(let i=0; i < e.dataTransfer.files.length; i++){
                    if(/^audio\//i.test(e.dataTransfer.files[i].type)){
                        this.$emit("add_file", e.dataTransfer.files[i]);
                    }
                }
            },

            /**
             * Drag-n-drop begin
             *
             * @param {Event} e
             */
            onDragEnter (e) {
                e.stopPropagation();
                e.preventDefault();
                this.isEnter = true;
            },
            /**
             * Drag-n-drop over component
             *
             * @param {Event} e
             */
            onDragLeave (e) {
                e.stopPropagation();
                e.preventDefault();
                console.log('onDragLeave', e);
                this.isEnter = false;
            },
            /**
             * Drag-n-drop over component
             *
             * @param {Event} e
             */
            onDragOver (e) {
                e.stopPropagation();
                e.preventDefault();
                e.dataTransfer.dropEffect = 'copy';
            },
        },

        computed: {
            containerClass(){
                return this.isOver ? "bg-shadow" : '';
            }
        },
        // Component mounted event handler
        mounted () {
            // this.$refs.mainContainer.addEventListener('dragenter', this.onDragEnter, true);
            // this.$refs.mainContainer.addEventListener('dragleave', this.onDragLeave, true);
            this.$refs.mainContainer.addEventListener('drop', this.onDrop, true);
            this.$refs.mainContainer.addEventListener('dragover', this.onDragOver, true);
            // Add screen wrapper listeners to stop drag-n-drop
            // this.$refs.dragNDropScreenWrapper.addEventListener('drop', this.dropOff);
            // this.$refs.dragNDropScreenWrapper.addEventListener('dragleave', this.dragOff);
        },
    }
</script>