<template>
    <div>
        <file-select @add_file="addFile" class="file-list">
            <file-list v-model="fileList"
                       style="text-align: left"
                       @select="select"
                       :current-item="currentItem"
            ></file-list>
        </file-select>
        <br/>

        <player controls :src="currentTrack"
                @prev="prevPlay"
                @next="nextPlay"
                @end="endPlay"

                :ctx="ctx"
                :destination="destination"
        ></player>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import FileSelect from './LocalFiles/fileSelect';
    import FileList from './LocalFiles/fileList';
    import Player from '../../components/player';
    import CtxMix from '../MixinsCtx';

    export default {
        components: {FileSelect, FileList, Player},

        /**
         * event "input_gains" current source
         */
        data() {
            return {
                fileList: [],
                currentItem: -1,
            }
        },

        props: {
            ...CtxMix.props,
        },

        methods: {
            addFile(file) {
                this.fileList.push(file);
                // AddMeta(file);
            },

            select(key) {
                this.currentItem = key;
                this.ctx.resume();
            },

            endPlay() {
                if (this.currentItem < this.fileList.length - 1) {
                    this.currentItem++;
                }
            },

            nextPlay() {
                if (this.currentItem == this.fileList.length - 1) {
                    this.currentItem = 0;
                } else if ((this.currentItem >= 0) && (this.currentItem < this.fileList.length)) {
                    this.currentItem++;
                }
            },

            prevPlay() {
                if (this.currentItem == 0) {
                    this.currentItem = this.fileList.length - 1;
                } else if ((this.currentItem >= 0) && (this.currentItem < this.fileList.length)) {
                    this.currentItem--;
                }
            },
        },

        computed: {
            currentTrack() {
                if ((this.currentItem >= 0) && (this.currentItem < this.fileList.length)) {
                    return this.fileList[this.currentItem];
                }

                return null;
            }
        }
    }
</script>