<template>
    <div class="player-container" :class="browserClass">
        <div class="player-button" @click="$emit('prev')">&lt;</div>
        <div class="player-button" @click="$emit('next')">&gt;</div>
        <div class="player-audio-container">
            <audio :src="urlSrc" autoplay controls ref="audio"></audio>
        </div>
    </div>

</template>

<style lang="scss" scoped>
    audio {
        width: 100%;
        height: 100%;
    }

    .player-button {
        text-align: center;
        float: left;
        border-right: 1px solid #eee;
        flex-basis: 3em;
        flex-grow: 0;
        line-height: 36px;
        font-weight: bold;
        cursor: default;
        &:hover{
            color: #aaf;
        }
    }

    .player-audio-container {
        flex-grow: 1;
    }

    .player-container {
        color: #eee;
        position: fixed;
        left: 0;
        display: flex;
        bottom: 0;
        width: 100%;
        background-color: #333;
        align-items: stretch;
        height: 36px;


        &.browser-chrome {
            background-color: #eee;
            border-top: 1px solid #333;
            color: #777;

            & .player-button {
                border-right: 1px solid #333;
                font-weight: bold;
            }
        }
    }
</style>

<script>
    import CtxMix from '../AudioContext/MixinsCtx';

    export default {
        mixins: [ CtxMix ],

        props: {
            src: File,
        },

        data(){
            return {
                audioSrc: null,
                urlSrc: null,
            }
        },

        watch: {
            src(file){
                this.urlSrc = file instanceof File ? URL.createObjectURL(file) : null;
            },

            /**
             * Удаляем из памяти объект фпйла
             * @param newVal
             * @param oldVal
             */
            urlSrc(newVal, oldVal){
                if(oldVal) {
                    console.log("revoked ", oldVal, newVal);
                    URL.revokeObjectURL(oldVal);
                }
            },
        },

        beforeDestroy(){
            if(this.urlSrc){
                URL.revokeObjectURL(this.urlSrc)
                this.urlSrc = null;
            }
        },

        methods: {
            endPlay(){
                this.$emit('end');
            },

            errorAudio(e){
                console.log(e);
            }
        },

        computed: {
            browserClass(){
                if(window.chrome){
                    return "browser-chrome";
                }else {
                    return '';
                }
            },
        },

        mounted(){
            /** @link https://developer.mozilla.org/ru/docs/Web/Guide/Events/Media_events */
            this.$refs.audio.addEventListener('ended', this.endPlay, true);
            this.$refs.audio.addEventListener('error', this.errorAudio, true);
            this.setGains([ this.ctx.createMediaElementSource(this.$refs.audio) ]);

            // console.log('player mounted', this.destination);
        }
    }
</script>