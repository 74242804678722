<template>
    <ul>
        <li v-for="(file,key) in fileList" :key="key" @click="selectItem(key)" :class="key == currentItem ? 'current' : ''">
            {{ file.name }}
            <span class="file-remove" @click="(e)=>{removeItem(key,e);}">x</span>
        </li>

    </ul>

</template>

<style lang="scss" scoped>
    ul {
        padding: .5em 1em;
        display: inline-block;

    }
    li {
        display: block;
        list-style: none;
        margin: -.5em 0;
        cursor: pointer;
        padding: .5em .5em .5em 2em;
        border-radius: .25em;
        line-height: 1.5em;

        &.current {
            padding-left: 1em;
            &:before {
                content: ">";
            }
        }

        &:hover {
            background-color: #eee;
        }
    }

    .file-item {
    }

    .file-remove {
        cursor: pointer;
        color: red;
        font-size: .9em;
        vertical-align: top;
        font-weight: bold;
        float: right;
        margin-left: 1em;
        width: 1em;
        height: 1em;
        line-height: .9em;
        text-align: center;

        &:hover {
            background-color: red;
            color: white;
            border: 1px solid red;
            border-radius: 1em;
        }
    }
</style>

<script>
    export default {
        model: {
            prop: "fileList",
            event: 'change'
        },

        props: {
            fileList: Array,
            currentItem: Number,
        },

        methods: {
            removeItem(key,e){
                e.stopPropagation();
                e.preventDefault();
                this.fileList.splice(key,1);
                this.$emit("change", this.fileList);
                if(key < this.currentItem){
                    this.selectItem(this.currentItem - 1);
                }
            },

            selectItem(key){
                this.$emit("select", key);
            }
        }
    }
</script>