/**
 * Возвращает множитель геометрической прогрессии
 *
 * @param {int} frameLength кол-во частот при разложении в ряд Фурье
 * @param {int} intervalCount кол-во интервалов в логарифмической шкале
 *
 * @return {float}
 */
export function frqInterval (frameLength, intervalCount) {
    const q = [2, 2.5938];

    const result = [ makeIntervals(q[0], intervalCount), makeIntervals(q[1], intervalCount) ];

    let timeout = 50;

    while ((q[0] != q[1]) && (frameLength != result[1].max) && (timeout-- >= 0)){
        if(result[0].max == result[1].max){
            q[1] = q[0] + (q[1] - q[0]) * 1.5
            result[1] = makeIntervals(q[1], intervalCount);
            continue;
        }

        q.push( q[1] - (q[1] - q[0]) * (frameLength - result[1].max) / (result[0].max - result[1].max) );
        q.shift();

        result.push( makeIntervals(q[1], intervalCount));
        result.shift();
    }

    return result[1].interval;
}

/**
 * making logarifmic intervals
 * @param {float} q
 * @param {int} intervalLength
 * @return {Array}
 */
function makeIntervals (q, intervalLength) {

    if(q <= 1){
        throw new Error('q must be > 1. q=' + q);
    }
    let step = 1;
    const result = [ 0 ];

    let timeout = 500;
    while ((result.length < intervalLength + 1) && (timeout-- >=0)){
        step *= q;

        result.push(result[result.length - 1] + Math.floor(step));
    }

    return { interval: result, max: result[result.length - 1] + 0 };
}