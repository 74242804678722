import Vue from 'vue/dist/vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import configConstructor from './config';

// import 'font-awesome/css/font-awesome.css'
import './assets/scss/main.scss'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { path: '*', component: App },
    ]
});

console.log('#app', document.getElementById('app'));
new Vue({
    router,
    data(){
        const self = this;
        const config = configConstructor(router, (newConfig) => {
            console.log('set new config', newConfig );
            self.config = newConfig; });

        return {
            config: config,
        }
    },
    template: `<router-view class="view" :config="config"></router-view>`,
    el: "#app",
});
