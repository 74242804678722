<template>
    <element-container>

        <component v-bind="$attrs"
                   :params="config.p"
                   @params="updateParams"
                   v-bind:is="componentType"
        ></component>

        <template slot="header">
            <b>Источник:</b>
            <label v-for="(currTypeLabel, currType) in types" :key="currType">{{ currTypeLabel }}
                <input type="radio" v-model="typeModel" :value="currType" />
            </label>
        </template>
    </element-container>
</template>

<style lang="scss" scoped>

</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    export const TYPE_PLAYER = 'Плеер файлов' ;
    export const TYPE_NOISE = 'Генератор шума';

    const types = { TYPE_PLAYER, TYPE_NOISE };

    import player from './Source/LocalFiles';
    import noise from './Source/Noise';

    export default{
        // mixins: [ Mixin ],
        inheritAttrs: false,

        props: {
            config: Object,
        },
        data(){

            return {
                type: TYPE_PLAYER,
                types,
            }
        },

        components: { ElementContainer, player, noise },

        computed: {
            componentType: {
                get() {
                    const resultType = Object.keys(types).find((key) => (
                        this.config.t.toLowerCase() == key.replace('TYPE_', '').toLowerCase()
                    )).replace('TYPE_', '').toLowerCase();

                    return resultType;
                },

            },

            typeModel: {
                get(){
                    const resultType = Object.keys(types).find((key) => (
                        this.config.t.toLowerCase() == key.replace('TYPE_', '').toLowerCase()
                    ));

                    return resultType;
                },

                set(type){
                    this.$emit('config', {
                        t: type.toLowerCase().replace('type_', ''),
                        p: this.config.p,
                    });
                }
            }
        },

        methods: {
            updateParams(newParams){
                this.$emit('config', {
                    t: this.config.t,
                    p: newParams,
                });
            },
        },
    };
</script>