export default {
    props: {
        ctx: AudioContext,
        destination: Array,
    },

    data(){
        return {
            gainsIn: [ ], // Входные узлы
            gainsOut: [ ], // Выходные узлы
        }
    },

    watch: {
        destination(newVal, oldVal) {
            //console.log('watch destination', oldVal, newVal, JSON.stringify(this.gainsOut), JSON.stringify(this.$vnode.tag));

            this.gainsOut.forEach((gain) => {
                oldVal.forEach((oldDest) => {
                    gain.disconnect(oldDest);
                });

                newVal.forEach((newDest) => {
                    gain.connect(newDest);
                });
            });
        },
    },

    methods: {
        setGainsOut(newGains){
            //console.log('set gainsOut', JSON.stringify(this.gainsOut),  JSON.stringify(newGains), JSON.stringify(this.$vnode.tag));
            this.destination.forEach((dest) => {

                this.gainsOut.forEach((gain) => {
                    gain.disconnect(dest);
                });

                newGains.forEach((gain) => {
                    gain.connect(dest);
                });
            });

            this.gainsOut = newGains;
        },

        setGainsIn(newGains){
            //console.log('set gainsIn', JSON.stringify(this.gainsIn), JSON.stringify(newGains), JSON.stringify(this.$vnode.tag));

            if((this.gainsIn.length == newGains.length) && this.gainsIn.every((gain, index) => (gain === newGains[index]))){
                // console.log('not new Gains', JSON.stringify(newGains), JSON.stringify(this.$vnode.tag));
            }else {

                this.gainsIn = newGains;
                this.$emit("input_gains", newGains);
            }
        },

        setGains(newGains) {
            // console.log('set gains', JSON.stringify(this.gains),  JSON.stringify(newGains), JSON.stringify(this.$vnode.tag));
            this.setGainsIn(newGains);
            this.setGainsOut(newGains);
        },
    },

    mounted(){
        console.log('mounted mixin', this);
        this.$emit("input_gains", this.gainsIn);
        this.destination.forEach((dest) => {
            this.gainsOut.forEach((gain) => {
                gain.connect(dest);
            });
        });
    },

    beforeDestroy(){
        this.gainsOut.forEach((gain) => {
            this.destination.forEach((dest) => {
                gain.disconnect(dest);
            });
        });
    },
}
