<template>
    <div class="element-container">
        <div class="element-header"><div class="element-header-container"><slot name="header">&nbsp;</slot></div>
            <span class="close-container" @click="$emit('close')">
                <font-awesome-icon icon="times" size="1x"></font-awesome-icon>
                <font-awesome-icon icon="times-circle" size="1x"></font-awesome-icon>
            </span>
        </div>
        <div class="element-body"><slot></slot></div>
        <div class="element-footer"><slot name="footer">&nbsp;</slot></div>
    </div>
</template>

<style lang="scss" scoped>
    $border-radius: 1em;

    .element-container {
        border: 2px solid #888888;
        border-radius: $border-radius;
        margin: .5em auto;
    }

    .element-body {
        padding: 0.5em 1em;
    }

    .element-header {
        @extend .element-body;
        background-color: #acd2c5;
        h1, h2, h3, h4, h5 {
            margin: 0;
        }
        border-top-left-radius : $border-radius;
        border-top-right-radius: $border-radius;

        :after {
            clear: both;
        }
    }

    .element-header-container {
        display: inline-block;
    }

    .element-footer {
        @extend .element-body;
        background-color: #b4c4d2;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .close-container {
        color: #333;

        &:not(:hover) > *:last-child {
            display: none;
        }

        &:hover > *:first-child {
            display: none;
        }

        vertical-align: top;
        float: right;
        display: block;
        cursor: pointer;
        width: 2em;
        text-align: center;
    }
</style>

<script>
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

    library.add( faTimes, faTimesCircle );

    export default {
        components: { FontAwesomeIcon },

    }
</script>