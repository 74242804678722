export default {
    methods: {
        timeFormat(val){
            val = this._toFixedDigit(val);
            console.log(`time ${val}`);
            if(val >= 1){
                return `${parseInt(val)} сек`;
            }else if(val >= .001){
                return `${parseInt(val * 1000)} мс`;
            }else if(val >= .000001){
                return  `${parseInt(val * 1000000)} мкс`;
            }else {
                return  `${val * 1000000000} нс`;
            }
        },

        freqFormat(f){
            if(f >= 1000000){
                return `${parseInt(f / 1000000)} МГц`;
            }else if(f >= 1000){
                return `${parseInt(f / 1000)} кГц`;
            }else {
                return `${parseInt(f)} Гц`;
            }
        },

        levelFormat(level){
            return this._toFixedDigit( 20 * Math.log10(level) ).toString() + ' дБ' ;
        },

        _toFixedDigit(val, num = 2){
            num--;
            const countDigit = Math.floor( Math.log10(Math.abs(val)) );
            return  Math.round( val / (10 ** (countDigit - num))) * (10 ** (countDigit - num));
        }

    }
}
