<template>
    <element-container class="boost-container" v-on="$listeners">
        <h4 slot="header">Фильтр Boost-инг</h4>

        <small-container class="boost-inner-container"
                         v-for="(filter, num) in filters" :key="num"
                         @close="removeFilter(num)">

            <div>
                <label>ФНЧ<input type="radio"
                                 value="0"
                                 :checked="!filter.hf"
                                 @input="setFilterParam(num, 'hf', 0)"
                /></label>

                <label>ФВЧ<input type="radio"
                                 value="1"
                                 :checked="filter.hf"
                                 @input="setFilterParam(num, 'hf', 1)"
                /></label>
            </div>

            <div>
                <span class="boost-label">Частота среза ( {{ freqFormat(filter.f) }} ):</span>
                <input type="range" min="0" max="30"
                       :value="filter.f" @input="(e) => setFilterParam(num, 'f', e.target.value)"
                />
            </div>

            <div>
                <span class="boost-label">Коэф-нт boost-инга ( {{ kFormat(filter.k) }} ):</span>
                <input type="range" min="0" max="30"
                       :value="filter.k" @input="(e) => setFilterParam(num, 'k', e.target.value)"
                />
            </div>

            <div>
                <label> Не менять спад сигнала:
                    <input type="checkbox" value="1" :checked="filter.d"
                           @input="(e) => setNoDecay(num, e)"
                    />
                </label>
            </div>

            <div>
                <span class="boost-label">Время действия импульса ( {{ timeFormat(filter.t) }}):</span>
                <input type="range" min="0" max="30"
                       :value="filter.t" @input="(e) => setFilterParam(num, 't', e.target.value)"
                />
            </div>
        </small-container>

        <a href="#" @click="addFilter">+ Добавить фильтр</a>

        <template slot="footer">
            Основной канал:
              <label>Вкл. <input type="radio" v-model="original" value="1" /></label>

              <label>Выкл. <input type="radio" v-model="original" value="0" /></label>

            <label class="boost-footer-onfilters-label">Фильтры включены:
                <input type="checkbox" :value="true" v-model="filtersOn" />
            </label>

        </template>


    </element-container>
</template>

<style lang="scss">
    .boost-container {
        input[type="range"] {
            max-width: 140px;
            display: inline;
            vertical-align: middle;
        }

        .boost-label {
            min-width: 20em;
            display: inline-block;
            text-align: right;
        }

        .boost-inner-container {
            border: 1px solid #ddd;
            padding: 7px;

            .element-body {
                display: flow-root;
            }
        }

        .boost-footer-onfilters-label {
            display: inline-block;
            margin-left: 2em;
        }
    }
</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    import SmallContainer from '../components/smallElementContainer';

    import CtxMix from './MixinsCtx';

    export default {
        mixins: [ CtxMix ],
        maxInstances: 1,

        components: { ElementContainer, SmallContainer },

        props: {
            params: [ Object, Array ],
        },

        data(){
            const sProc = this.ctx.createScriptProcessor(1024);

            const self = this;
            const filteredSamples = [ ];
            const currLevel0Samples = [ ];
            const currLevelSamples = [ ];

            // let maxLog = 10;

            sProc.onaudioprocess = function(audioProcessingEvent) {
                // The input buffer is the song we loaded earlier
                const inputBuffer = audioProcessingEvent.inputBuffer;

                // The output buffer contains the samples that will be modified and played
                const outputBuffer = audioProcessingEvent.outputBuffer;

                const outEnable = !!self.original;

                for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                    const outputData = outputBuffer.getChannelData(channel);
                    const inputData = inputBuffer.getChannelData(channel);

                    for (let sample = 0; sample < inputBuffer.length; sample++) {
                        outputData[sample] = outEnable ? inputData[sample] : 0;
                    }

                    const normalizeSamplesArray = function (samplesArray) {
                        while (samplesArray.length < channel + 1){
                            samplesArray.push([ ]);
                        }

                        while (samplesArray[channel].length < self.filters.length){
                            samplesArray[channel].push( 0 );
                        }
                    }

                    normalizeSamplesArray(filteredSamples);
                    normalizeSamplesArray(currLevel0Samples);
                    normalizeSamplesArray(currLevelSamples);

                    for ( let filterNum = 0; filterNum < self.filtersForCalc.length; filterNum++) {
                        const hightFreq = self.filtersForCalc[filterNum].hf;
                        const f = self.filtersForCalc[filterNum].f;
                        const k = self.filtersForCalc[filterNum].k;
                        const decay = self.filtersForCalc[filterNum].d;
                        const T = self.filtersForCalc[filterNum].t;
                        const T0 = self.ctx.sampleRate * 0.05; // 100 мс постоянная времени фильтра уровня
                        const kMax = self.kmax;

                        for (let sample = 0; sample < inputBuffer.length; sample++) {
                            filteredSamples[channel][filterNum] = (inputData[sample] + (f - 1) * filteredSamples[channel][filterNum]) / f;

                            const currSample = hightFreq ? (inputData[sample] - filteredSamples[channel][filterNum]) : filteredSamples[channel][filterNum];

                            const level = Math.abs(currSample); // уровень отсчёта
                            currLevel0Samples[channel][filterNum] = (level + (T0 - 1) * currLevel0Samples[channel][filterNum]) / T0;
                            currLevelSamples[channel][filterNum] = (level + (T - 1) * currLevelSamples[channel][filterNum]) / T;

                            // Изменение уровня отфильтрованного сигнала
                            // 0 < levelBust < 1
                            const levelBust = currLevelSamples[channel][filterNum] - currLevel0Samples[channel][filterNum];

                            if (levelBust >= 0) {
                                // нарастание уровня сигнала
                                outputData[sample] = outputData[sample] + currSample * k * levelBust;

                            } else {
                                // спад уровня
                                if (!decay) {
                                    // корректируем уровень на спаде
                                    outputData[sample] = outputData[sample] + (currSample * k * levelBust / kMax);
                                }
                            }
                        }
                    }
                }
            };

            return {
                sProc,

                filtersOn: true,
            }
        },

        mounted(){
            this.setGains([ this.sProc ]);
        },

        computed: {
            normalizedParams(){
                if(!Array.isArray(this.params)){
                    return [ 1 ];
                }

                return [
                    this.params[0] ? 1 : 0,
                    ...( this.params.slice(1).map((item) => (Object.assign({
                        hf: 0, // ФНЧ - 0, ФВЧ - 1
                        f: 0,
                        k: 1,
                        d: 0,
                        t: 0,
                    }, item))) )
                ];
            },

            filters(){
                return this.normalizedParams.slice(1);
            },

            filtersForCalc(){
                const self = this;

                if(!this.filtersOn){
                    return [ ];
                }

                // приведения коэффициентов фильтров в вид удобеый для расчётов
                const results = this.filters.map((item) => {
                    return {
                        hf: !!item.hf,
                        f: self.ctx.sampleRate / (20 * Math.pow(1.257, item.f)),
                        k: Math.round(Math.pow(1.257, Math.round(item.k.toString()))),
                        d: !!item.d,
                        t: Math.round(0.1 * Math.pow(1.12, item.t) * self.ctx.sampleRate),
                    }
                });

                console.log('filtersForCalc', results);
                return results;
            },

            kmax(){
                return Math.round(Math.pow(1.257, 30));
            },

            original: {
                get(){
                    return this.normalizedParams[0]
                },

                set(val){
                    const params = this.normalizedParams;
                    params[0] = Math.round(val);
                    this.$emit('set_params', params );
                }
            }
        },

        methods: {
            addFilter(e){
                e.preventDefault();
                const params = this.normalizedParams;
                params.push({});
                this.$emit('set_params', params );
            },

            removeFilter(k){
                const params = this.normalizedParams;
                params.splice(k + 1,1);
                this.$emit('set_params', params );
            },

            timeFormat(val){
                const result = Math.round(100 * Math.pow(1.12, val));
                return result + " мс";
            },

            freqFormat(val){
                return Math.round(20 * Math.pow(1.257, val)) + " Гц";
            },

            kFormat(val){
                return Math.round(Math.pow(1.257, val));
            },

            setFilterParam(num, param, val){

                const params = this.normalizedParams;
                console.log('setFilterParam', num, param, val, params[num + 1]);

                params[num + 1][param] = val;
                this.$emit('set_params', params );
            },

            setNoDecay(num, e){
                this.setFilterParam(num, 'd', e.target.checked ? e.target.value : 0);
            }
        }
    }
</script>
