<template>

    <element-container @close="$emit('close')">
        <div class="analyze-container" :style="{ height: containerHeight + 'px' }">
            <div class="analyze-result"
                 v-for="(af, key) in a"
                 :key="key"
                 :style="{height: af + 'px'}"
            ></div>
        </div>

        <template slot="footer">
            Частота диск-ции: {{ ctx.sampleRate }}Гц.
            Полоса частот: {{ Math.round(ctx.sampleRate / windowFrame)}}Гц - {{ Math.round( ctx.sampleRate / 2 ) }}Гц.
            Кол-во интервалов <input type="number" v-model="count" />
            <br/>
            <em>* Шкала частот и уровней логарифмические</em>

        </template>

        <h4 slot="header">Частотный анализатор</h4>
    </element-container>

</template>

<style lang="scss" scoped>
    .analyze-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        max-width: 700px;
        margin: 1em auto;
        height: 512px;
    }

    .analyze-result {
        flex-grow: 1;
        background-color: #ff8936;
    }

    input[type="number"] {
        width: 4em;
        text-align: center;
    }
</style>

<script>
    import CtxMix from './MixinsCtx';
    import { frqInterval } from './spectrAnalyzerLib';
    import ElementContainer from '../components/ElementContainer';

    export default {
        mixins: [ CtxMix ],
        maxInstances: 3,

        props: {
            params: [Object, Number],
        },

        components: { ElementContainer },
        data(){
            const analyzer = this.ctx.createAnalyser();
            analyzer.smoothingTimeConstant = 0.3;

            const windowFrame = 2048;

            const processor = this.ctx.createScriptProcessor(windowFrame * 1, 1, 1);

            return {
                ctxSource: analyzer,
                windowFrame,
                processor,

                containerHeight: 200,
                a: [],
                height_k: 1,
            };
        },

        computed: {
            intervals() {
                const resInterval = frqInterval(this.ctxSource.frequencyBinCount, Math.floor(this.count));
                if(resInterval[resInterval.length - 1] > this.ctxSource.frequencyBinCount){
                    this.count = Math.floor(this.count) - 1; // eslint-disable-line
                    return this.intervals;
                }

                return resInterval;
            },

            count: {
                get(){
                    return this.params > 0 ? this.params : 15;
                },

                set(val){
                    this.$emit('set_params', Math.round(val.toString()));
                }
            },
        },

        mounted(){
            this.setGains([ this.ctxSource ]);

            const self = this;
            this.ctxSource.fftSize = this.windowFrame;
            const bufferLength = this.ctxSource.frequencyBinCount;
            console.log('frequencyBinCount', bufferLength);
            const dataArray = new Uint8Array(bufferLength);

            // this.processor.onaudioprocess = () => {
            setInterval(() => {
                self.ctxSource.getByteFrequencyData(dataArray);
                const intervals = [ ...self.intervals ];

                const result = [ 0 ];

                dataArray.forEach((item, index) => {
                    result[result.length - 1] += item;
                    if(index == intervals[1] - 1){
                        result[result.length - 1] = result[result.length - 1] * this.height_k / ( intervals[1] - intervals[0] );

                        if(result[result.length - 1] > this.containerHeight){
                            this.height_k *= this.containerHeight / result[result.length - 1]
                        }
                        intervals.shift();
                        result.push( 0 );
                    }
                });

                result.pop();

                self.a = result;
            }
            //;
            , 300);

        },
    }
</script>
