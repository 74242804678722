<template>
    <div class="element-container">
        <span class="close-container" @click="$emit('close')">
            <font-awesome-icon icon="times" size="1x"></font-awesome-icon>
            <font-awesome-icon icon="times-circle" size="1x"></font-awesome-icon>
        </span>

        <div class="element-body">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .element-container {
        border: 1px solid #ddd;;
        padding: 7px;

        margin-top: .5em;
        border-radius: .5em;

        &:hover {
            border: 1px solid #aaa;

            padding: 7px;
        }
    }

    .element-body {
        display: inline-block;
    }

    .close-container {
        color: #333;

        &:not(:hover) > *:last-child {
            display: none;
        }

        &:hover > *:first-child {
            display: none;
        }

        vertical-align: top;
        float: right;
        display: block;
        cursor: pointer;
        width: 2em;
        text-align: center;
    }

</style>

<script>
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

    library.add( faTimes, faTimesCircle );

    export default {
        components: { FontAwesomeIcon },

    }
</script>