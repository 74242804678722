<template>
    <element-container v-on="$listeners">
        <button @click="setState">
            <template v-if="state_on">
                <font-awesome-icon class="state-on" icon="volume-up" size="4x" />
            </template>

            <template v-else>
                <font-awesome-icon class="state-off" icon="volume-off" size="4x" />
            </template>
        </button>
    </element-container>

</template>

<style lang="scss" scoped>
    button {
        border-radius: .5em;
        border: 2px solid #aaa;
        padding: .5em;
        width: 6em;
        text-align: center;
        background: linear-gradient(135deg, #fff, #aaa);

        &:active {
            background: linear-gradient(315deg, #fff, #aaa);
        }
    }

    .state-on {

    }

    .state-off {
        color: red;
    }
</style>

<script>
    import MixinsCtx from './MixinsCtx';
    import ElementContainer from '../components/smallElementContainer';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faVolumeUp, faVolumeOff } from '@fortawesome/free-solid-svg-icons'

    import { RESOURCE_DEST } from '../resourceTypes';

    library.add( faVolumeUp, faVolumeOff );

    export default {
        mixins: [ MixinsCtx ],
        maxInstances: 1,

        props: {
            params: [ Object, Boolean ],
        },

        components: { FontAwesomeIcon, ElementContainer },

        methods: {
            setState(e){
                e.preventDefault();

                if(this.params) {
                    this.setOff();
                }else {
                    this.setOn();
                }
            },

            setOn(){
                this.$emit('capture_resource',RESOURCE_DEST( function () {
                    this.$emit('set_params', false);
                }.bind(this) ));

                this.$emit('set_params', true);
                this.ctx.resume().then(() => {
                    console.log('Playback resumed successfully');
                });
            },

            setOff(){
                this.$emit('set_params', false);
            },
        },

        computed: {
            state_on(){
                if(this.params){
                    console.log('set destination gain');
                    this.setGains([ this.ctx.destination ]);
                }else {
                    console.log('clear destination gain');
                    this.setGains([ ]);
                }

                return !!this.params;
            }
        },

        mounted(){
            this.setOn();
        }
    }
</script>
