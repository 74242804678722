<template>
    <element-container class="impulse-noise__container" v-on="$listeners">
        <h4 slot="header">Фильтр Импульсных помех</h4>

        <div class="regulator-container">
            <span class="label-input">Максимальная длительность импульса ( {{ intervalFormat }} ):</span>
                <input type="range" :min="0" :max="10"
                       :disabled="config.mode == MODE.OFF"
                       v-model="config.interval"
                />
        </div>

        <div class="regulator-container">
            <span class="label-input">Максимальное превышение уровня ( {{ levelFormat(level) }} ):</span>
            <input type="range" :min="1" :max="20"
                   :disabled="config.mode == MODE.OFF"
                   v-model="config.level"
            />
        </div>

        <div class="regulator-container">
            <span class="label-input">Частота среза фильтров предкоррекции
                ( {{ fFiltrFormat }} )
                <input type="checkbox"
                       v-model="config.useFPre"
                />:</span>
            <input type="range" :min="0" :max="15"
                   :disabled="config.mode == MODE.OFF || !config.useFPre"
                   v-model="config.fpre"
            />
        </div>

        <template slot="footer">
            Режим:
              <label >Вкл. <input type="radio" v-model="config.mode" :value="MODE.ON" /></label>
            |
              <label>Выкл. <input type="radio" v-model="config.mode" :value="MODE.OFF" /></label>
            |
              <label>Только шум:
                <input type="radio" v-model="config.mode" :value="MODE.NOISE" />
            </label>
        </template>
    </element-container>
</template>

<style lang="scss">
    .impulse-noise__container {
        .element-footer {
            label {
                display: inline-block;
                margin: 0 1em;
            }
        }

        .regulator-container{
            margin: .5em;
        }
        .label-input {
            width: 50%;
            text-align: right;
            display: inline-block;
            padding-right: .5em;
            vertical-align: middle;
        }

        input {
            vertical-align: middle;
        }

        input[disabled] {
            opacity: .4;
        }
    }
</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    // import SmallContainer from '../components/smallElementContainer';

    import CtxMix from './MixinsCtx';
    import MixinParamsObject from "@/AudioContext/MixinParamsObject";
    import MixinFormat from "@/AudioContext/MixinFormat";

    const BUFF_SIZE = 256;

    const MODE = Object.freeze({
        OFF: 0,
        ON: 1,
        NOISE: 2
    });

    export default {
        mixins: [ CtxMix, MixinParamsObject, MixinFormat ],
        maxInstances: 1,

        components: { ElementContainer },

        data(){
            return {
                MODE,

                config: {
                    mode: MODE.ON,
                    interval: 1,
                    level: 5,
                    useFPre: true,
                    fpre: 1
                },

                debug: {
                    max: 0
                }
            }
        },

        mounted(){
            const self = this;

            const sProc = this.ctx.createScriptProcessor(BUFF_SIZE);
            const BuffLevels = [ ];
            const Buffers = [ ];
            const x = [ ]; const y = [ ];

            // let count = 1024;
            // let flag = true;

            sProc.onaudioprocess = function (audioProcessingEvent){
                // The input buffer is the song we loaded earlier
                const inputBuffer = audioProcessingEvent.inputBuffer;

                // The output buffer contains the samples that will be modified and played
                const outputBuffer = audioProcessingEvent.outputBuffer;

                const k = Math.round(self.buffSize / BUFF_SIZE);

                // формируем единый буфер содержащий текущие и предидущие отсчёты
                for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                    if (Buffers.length < channel + 1) {
                        Buffers[channel] = Array(self.buffSize).fill(0);
                    }
                    while( Buffers[channel].length < self.buffSize){
                        Buffers[channel].push(0);
                    }

                    if(BuffLevels.length < channel + 1){
                        BuffLevels[channel] = Array(k * 3).fill(0);
                    }
                    while (BuffLevels[channel].length < (k * 3)){
                        BuffLevels[channel].push(0);
                    }

                    if(x.length < channel + 1){
                        x.push(0);
                    }
                    if(y.length < channel + 1){
                        y.push(0);
                    }

                    const kFilter = self.kFilter;
                    const inputData = self.config.useFPre ?
                        inputBuffer.getChannelData(channel).map(
                           (sample) => {
                               const result = sample * (kFilter + 1) -
                                   (x[channel] * kFilter);

                               x[channel] = sample;

                               // if(flag && x[channel] != 0){ console.log('sample', [sample, x[channel]]) }

                               return result;
                           }
                       ) : inputBuffer.getChannelData(channel);
                    // if(flag && x[channel] != 0){
                    //     console.log(inputData);
                    //     flag = false;
                    // }

                    const outputData = outputBuffer.getChannelData(channel);

                    BuffLevels[channel].push(inputData.reduce(
                                (result, sample) => (result + sample * sample),
                                0 ) / inputData.length
                    );
                    while (BuffLevels[channel].length > (k * 3)){
                        BuffLevels[channel].shift();
                    }

                    // Максимально допустимое значение семпла
                    const max = Math.sqrt(
                        [ ...BuffLevels[channel].slice(0, k),
                            ...BuffLevels[channel].slice(-1 * k)
                            ].reduce((result, sample) => (result + sample), 0)
                        / (2 * k)
                    ) * self.level;

                    Buffers[channel].splice(-1 * BUFF_SIZE).forEach((sample, indx) => {
                        switch (self.config.mode) {
                            case MODE.ON:
                                outputData[indx] = Math.abs(sample) > max ?
                                    max * Math.sign(sample) : sample
                                break;

                            case MODE.NOISE:
                                outputData[indx] = Math.abs(sample) > max ?
                                    sample - (max * Math.sign(sample)) : 0
                                break;

                            case MODE.OFF:
                                outputData[indx] = sample;
                                break;
                        }

                        if(self.config.useFPre){
                            outputData[indx] = (y[channel] * kFilter + outputData[indx]) / (kFilter + 1);
                            y[channel] = outputData[indx];
                        }
                    });

                    Buffers[channel].push( ...inputData );
                    while (Buffers[channel].length > self.buffSize){
                        Buffers[channel].shift();
                    }

                    // if( count-- < 0) {
                    //     self.debug.max = x.toString();
                    //     count = 1024;
                    // }
                }
            };

            this.setGains([sProc]);
        },

        computed: {
            buffSize(){
                return BUFF_SIZE * (2 ** (parseInt( this.config.interval )));
            },

            intervalFormat(){
                // console.log('intervalFormat', [this.buffSize,  this.ctx.sampleRate]);
                return this.timeFormat( this.buffSize / (this.ctx.sampleRate) );
            },

            level(){
                return Math.pow(10, parseInt(this.config.level) / 20);
            },

            kFilter(){
                return  5 * 1.05 ** (this.config.fpre + 1);
            },

            fFiltrFormat(){
                // console.log('sampleRate', this.ctx.sampleRate);

                return this.freqFormat(
                    Math.log((this.kFilter + 1) / this.kFilter) * this.ctx.sampleRate
                );
            }
        },
        //
        // watch: {
        //     buffSize(buffSize){
        //         console.log('watch buffSize', arguments);
        //         buffSize;
        //
        //     }
        // },
        methods: {
        }

    }

    // class Config{
    //     mode = MODE.ON;
    //
    //     applyParam(params){
    //         Object.keys(params).forEach((param) => {
    //             if(typeof this[param] != 'undefined'){
    //                 this[param] = params[param];
    //             }
    //         });
    //     }
    // }
</script>
