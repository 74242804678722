<template>
    <element-container v-on="$listeners">
        <template slot="header">
            <h4>Осцилограф</h4>
        </template>

        <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>

        <template slot="footer">
            Синхронизация:
            <label>
                <span class="synth-label synth-label_low">&nbsp;</span>
                <span class="synth-label synth-label_height">&nbsp;</span>
                <input type="radio" value="1" v-model="setSynth" />
            </label>
            &nbsp;
            <label>
                <span class="synth-label synth-label_height">&nbsp;</span>
                <span class="synth-label synth-label_low">&nbsp;</span>
                <input type="radio" value="-1" v-model="setSynth" />
            </label>
        </template>

    </element-container>
</template>

<style lang="scss" scoped>
    label {
        display: inline-block;

        &:after {
            clear: both;
            content: "";
            display: block;
        }
    }

    .synth-label {
        display: block;
        float: left;
        width: 1em;
        height: 100%;
        &:first-child {
            border-right: 1px solid #222;
        }
    }

    .synth-label_height {
        border-top: 1px solid #222;
    }

    .synth-label_low {
        border-bottom: 1px solid #222;
    }
</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    import CtxMix from './MixinsCtx';

    export default {
        mixins: [ CtxMix ],
        maxInstances: 3,

        components: { ElementContainer },

        props: {
            params: [ Object, Number ],
        },

        data(){
            const analyzer = this.ctx.createAnalyser();
            analyzer.smoothingTimeConstant = 0.3;

            const windowFrame = 2048;

            // const processor = this.ctx.createScriptProcessor(windowFrame * 1, 1, 1);

            return {
                analyzer: analyzer,
                windowFrame,

                canvasHeight: 200,
                canvasWidth: 500,

                // syntcFront: 1, // -1
            };
        },

        computed: {
            syntcFront(){
                console.log('syntcFront', this.params > 0 ? 1 : -1);
                return this.params > 0 ? 1 : -1;
            },

            time: {
                set(value){
                    this.windowFrame = Math.pow(2, value);
                    this.analyzer.fftSize = this.windowFrame;
                },

                get(){
                    return Math.round(Math.log2( this.windowFrame ));
                }
            },

            setSynth: {
                get(){
                    return this.syntcFront;
                },

                set(value){
                    console.log('setParams', value, Math.round(value))
                    this.$emit('set_params', Math.round(value))
                }
            }
        },

        mounted(){
            this.setGains([ this.analyzer ]);

            const ctx = this.$refs.canvas.getContext("2d");

            const self = this;
            this.analyzer.fftSize = this.windowFrame;
            const bufferLength = this.analyzer.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            setInterval(() => {
                self.analyzer.getByteTimeDomainData(dataArray);
                ctx.clearRect(0,0, this.canvasWidth, this.canvasWidth);
                ctx.strokeStyle = "#2fb92e";
                ctx.beginPath();

                // синхронизация
                dataArray.reduce((res, curr, index, srcArr) => {
                    if(res.length < 2){
                        res.push(curr);

                    // синхронизируемся по первой половине данных
                    }else if(index + 1 < srcArr.length / 2){

                        // если найден более крутой фронт, обнуляем массив результата
                        if((srcArr[index + 1] - curr) * this.syntcFront > (res[1] - res[0]) * this.syntcFront){
                            res = [ curr ];
                        }

                    // Заполняем массив результата до половины исходного массава
                    }else if( res.length < srcArr.length / 2){
                        res.push(curr);
                    }

                    return res;

                }, [ ]).map((item, index) => {
                    // нормализация
                    // инверсия и усреднение
                    item = (127 - item) + self.canvasHeight / 2;

                    // нормализация
                    if(item > self.canvasHeight){
                        self.canvasHeight += (item - this.canvasHeight) * 2;
                        item = this.canvasHeight;
                    }else if(item < 0){
                        self.canvasHeight += -2 * item;
                        item = 0;
                    }

                    // и рисование
                    if (index == 0){
                        ctx.moveTo(index, item);
                    }else if(index < self.canvasWidth - 1){
                        ctx.lineTo(index, item);
                    }
                });
                ctx.stroke();
            }, 200)
        }
    }
</script>
