<template>
    <element-container class="headset-container" v-on="$listeners">
        <h4 slot="header">Фильтр для наушников</h4>

        <small-container class="headset-inner-container"
                         v-for="(filter, num) in filters" :key="num"
                         @close="removeFilter(num)">
            <div>
                <span class="headset-label">Задержка ( {{ delayFormat(filter.d) }}):</span>
                <input type="range" min="0" max="40"
                       :value="filter.d" @input="(e) => setFilterParam(num, 'd', e.target.value)"
                />
            </div>
            <div>
                <span class="headset-label">Коэффициент передачи ( {{ filter.k/20 }} ):</span>
                <input type="range" min="0" max="20"
                       :value="filter.k" @input="(e) => setFilterParam(num, 'k', e.target.value)"
                />
            </div>

            <div>
                <label> Инверсия:
                    <input type="checkbox" value="1" :checked="filter.i"
                           @input="(e) => setInverseCheckbox(num, e)"
                    />
                </label>
            </div>

            <div>
                <label>ФНЧ<input type="radio"
                                 value="0"
                                 :checked="!filter.hf"
                                 @input="setFilterParam(num, 'hf', 0)"
                /></label>

                <label>ФВЧ<input type="radio"
                                 value="1"
                                 :checked="filter.hf"
                                 @input="setFilterParam(num, 'hf', 1)"
                /></label>
            </div>
            <div>
                <span class="headset-label">Частота среза ( {{ freqFormat(filter.f) }} ):</span>
                <input type="range" min="0" max="20"
                       :value="filter.f" @input="(e) => setFilterParam(num, 'f', e.target.value)"
                />
            </div>

            <div>
                <label>Прямой <input type="radio"
                                     value="0"
                                     :checked="!filter.c"
                                     @input="setFilterParam(num, 'c', 0)"
                /></label>

                <label>Перекрёстный
                    <input type="radio"
                           value="1" :checked="!!filter.c"
                           @input="setFilterParam(num, 'c', 1)"
                /></label>
            </div>
        </small-container>

        <a href="#" @click="addFilter">+ Добавить фильтр</a>

        <template slot="footer">
            Основной канал:
              <label>Вкл. <input type="radio" v-model="original" value="1" /></label>

              <label>Выкл. <input type="radio" v-model="original" value="0" /></label>

            <label class="headset-footer-onfilters-label">Фильтры включены:
                <input type="checkbox" :value="true" v-model="filtersOn" />
            </label>

        </template>


    </element-container>
</template>

<style lang="scss">
    .headset-container {
        input[type="range"] {
            max-width: 140px;
            display: inline;
            vertical-align: middle;
        }

        .headset-label {
            min-width: 20em;
            display: inline-block;
            text-align: right;
        }

        .headset-inner-container {
            border: 1px solid #ddd;
            padding: 7px;

            .element-body {
                display: flow-root;
            }
        }

        .headset-footer-onfilters-label {
            display: inline-block;
            margin-left: 2em;
        }
    }
</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    import SmallContainer from '../components/smallElementContainer';

    import CtxMix from './MixinsCtx';

    export default {
        mixins: [ CtxMix ],
        maxInstances: 1,

        components: { ElementContainer, SmallContainer },

        props: {
            params: [ Object, Array ],
        },

        data(){
            const sProc = this.ctx.createScriptProcessor(2048);

            console.log(sProc);

            //let NotFirst = 10;

            const self = this;
            const Buffers = [ Array(2048).fill(0), Array(2048).fill(0)];
            const currFilterSamples = [ ];

            sProc.onaudioprocess = function(audioProcessingEvent) {
                // The input buffer is the song we loaded earlier
                const inputBuffer = audioProcessingEvent.inputBuffer;

                // The output buffer contains the samples that will be modified and played
                const outputBuffer = audioProcessingEvent.outputBuffer;

                // формируем единый буфер содержащий текущие и предидущие отсчёты
                for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                    if (Buffers.length < channel + 1) {
                        Buffers[channel] = Array(2048).fill(0);
                    }

                    Buffers[channel] = Buffers[channel].slice(-2048);
                    Buffers[channel].push(...inputBuffer.getChannelData(channel));

                    while (currFilterSamples.length < channel + 1){
                        currFilterSamples.push([ ]);
                    }

                    while (currFilterSamples[channel].length < self.filters.length){
                        currFilterSamples[channel].push( 0 );
                    }
                }


                const outEnable = !!self.original;
                for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                    const outputData = outputBuffer.getChannelData(channel);

                    // инициируем результирующий буфер
                    for (let sample = 0; sample < inputBuffer.length; sample++) {
                        outputData[sample] = outEnable ? Buffers[channel][sample + 2048] : 0;
                    }

                    for ( let filterNum = 0; filterNum < self.filtersForCalc.length; filterNum++) {
                        const delay = self.filtersForCalc[filterNum].d > 2047 ? 2047 : self.filtersForCalc[filterNum].d;
                        const k = self.filtersForCalc[filterNum].k;
                        const f = self.filtersForCalc[filterNum].f < 1 ? 1 : self.filtersForCalc[filterNum].f;
                        const cross = self.filtersForCalc[filterNum].c;
                        const hightFreq = self.filtersForCalc[filterNum].hf;

                        for (let sample = 0; sample < inputBuffer.length; sample++) {

                            let CurrFilterSample = 0;
                            if(cross){
                                for (let sampleChannel = 0; sampleChannel < inputBuffer.numberOfChannels; sampleChannel++){
                                    if(sampleChannel != channel){
                                        CurrFilterSample += Buffers[sampleChannel][sample + 2048 - delay];
                                    }
                                }
                            }else {
                                CurrFilterSample = Buffers[channel][sample + 2048 - delay];
                            }

                            currFilterSamples[channel][filterNum] = (CurrFilterSample + (f - 1) * currFilterSamples[channel][filterNum]) / f;

                            if(hightFreq){
                                outputData[sample] = outputData[sample] +
                                    (CurrFilterSample - currFilterSamples[channel][filterNum]) * k;
                            }else {
                                outputData[sample] = outputData[sample] + currFilterSamples[channel][filterNum] * k;
                            }
                        }
                    }
                }
            };

            return {
                sProc,

                filtersOn: true,
            }
        },

        mounted(){
            this.setGains([ this.sProc ]);
        },

        computed: {
            normalizedParams(){
                if(!Array.isArray(this.params)){
                    return [ 1 ];
                }

                return [
                    this.params[0] ? 1 : 0,
                    ...( this.params.slice(1).map((item) => (Object.assign({
                        d: 0,
                        k: 5,
                        hf: 0,
                        f: 0,
                        c: 1,
                        i: 0,
                    }, item))) )
                ];
            },

            filters(){
                return this.normalizedParams.slice(1);
            },

            filtersForCalc(){
                const self = this;

                if(!this.filtersOn){
                    return [ ];
                }

                // приведения коэффициентов фильтров в вид удобеый для расчётов
                const results = this.filters.map((item) => {
                    return {
                        k: item.k / 20 * (item.i ? -1 : 1),
                        d: item.d == 0 ? 0 : (
                            Math.round(self.ctx.sampleRate * 100 * Math.pow(1.26, item.d) / 1000000)
                        ),
                        hf: !!item.hf,
                        f: self.ctx.sampleRate / (2 * 500 * Math.pow(1.2, item.f)),
                        c: !!item.c,
                    }
                });

                console.log('filtersForCalc', results);
                return results;
            },

            original: {
                get(){
                    return this.normalizedParams[0]
                },

                set(val){
                    const params = this.normalizedParams;
                    params[0] = Math.round(val);
                    this.$emit('set_params', params );
                }
            }
        },

        methods: {
            addFilter(e){
                e.preventDefault();
                const params = this.normalizedParams;
                params.push({});
                this.$emit('set_params', params );
            },

            removeFilter(k){
                const params = this.normalizedParams;
                params.splice(k + 1,1);
                this.$emit('set_params', params );
            },

            delayFormat(val){
                if(val == 0){ return "0 мкс"; }
                const result = Math.round(100 * Math.pow(1.26, val));
                if(result > 999){
                    return Math.round(result / 1000) + " мс";
                }else {
                    return result + " мкс";
                }
            },

            freqFormat(val){
                return (Math.round(5 * Math.pow(1.2, val)) / 10) + " кГц";
            },

            setFilterParam(num, param, val){

                const params = this.normalizedParams;
                console.log('setFilterParam', num, param, val, params[num + 1]);

                params[num + 1][param] = val;
                this.$emit('set_params', params );
            },

            setInverseCheckbox(num, e){
                this.setFilterParam(num, 'i', e.target.checked ? e.target.value : 0);
            }
        }
    }
</script>
