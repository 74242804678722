<template>
    <element-container class="equalizer-container" v-on="$listeners">
        <h4 slot="header">Эквалайзер</h4>

        <template slot="footer">
            Кол-во регуляторов <input type="number" v-model="count" />
            Приведенная добротность: <input type="range" class="q" v-model="Q"
                       min="0" max="90" step="1"
                       :title="realQ"
                />
            <br />
            <em>* Шкала частот и уровней логарифмические</em>
        </template>

        <div class="gains-container">
            <div class="gain-wrapper" v-for="(value, key) in gainValues" :key="key" :style="{ width: gainWidth + '%' }">
                <input type="range" class="gains" @dblclick="() => { setGainValue(key, 0) }"
                       :value="value" @input="(e) => setGainValue(key, e.target.value)"
                       :title="value"
                       min="-20" max="20" step="1"
                />
            </div>
        </div>
    </element-container>
</template>

<style lang="scss" scoped>
    .gains-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 130px;
    }

    .gain-wrapper {
        width: auto;
        overflow: visible;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }

    input.gains {
        display: inline-block;
        width: 120px;
        transform: translateX(-50%);
        margin: 0 50%;
    }

    .q {
        display: inline;
        vertical-align: middle;
        width: auto;
    }
</style>

<script>
    import ElementContainer from '../components/ElementContainer';
    import CtxMix from './MixinsCtx';

    const MAX_COUNT = 25;

    export default {
        mixins: [ CtxMix ],
        maxInstances: 3,

        components: { ElementContainer },

        props: {
            params: [ Object, Array ],
        },

        data(){
            return {
                windowFrame: 5,
                filterGains: [ ],
            }
        },

        computed: {
            gainWidth(){
                return 100 / this.gainValues.length;
            },

            count: {
                get(){
                    return this.gainValues.length;
                },

                set(value){
                    value = Math.round(value.toString());
                    if(value > MAX_COUNT){ value = MAX_COUNT; }
                    if(value < 3){ value = 3; }

                    const gainValues = [ ...this.gainValues ];

                    while (gainValues.length < value){
                        gainValues.push(0);
                    }

                    while (gainValues.length > value) {
                        gainValues.pop();
                    }

                    this.$emit('set_params', [this.Q, ...(gainValues.map((value) => (value + 20)))]);
                }
            },

            gainValues(){
                const values = (Array.isArray(this.params) ? this.params : [0, 20, 20, 20]).slice(1)
                    .map((value) => (value - 20));

                this.applyFilters(values);
                return values;
            },

            Q: {
                get() {
                    return (Array.isArray(this.params) ? this.params : [0])[0];
                },

                set(value){
                    const params = (Array.isArray(this.params) ? this.params : [0, 20, 20, 20]);
                    params[0] = Math.round(value.toString());
                    this.$emit('set_params', params);
                }
            },

            realQ(){
                const Q = this.Q - 45;
                if(Q == 0){
                    return 1;
                }else if(Q < 0){
                    return -1/Q;
                }else {
                    return Q;
                }
            }
        },

        methods: {
            setGainValue(index, value){
                const params = (Array.isArray(this.params) ? this.params : [0]);
                while (params.length - 1 <= index){
                    params.push(0);
                }

                params[index + 1] = Math.round( value.toString()) + 20;
                console.log('set value', params);
                this.$emit('set_params', params);
            },

            applyFilters(values){
                if(values.length < 1){ return; }

                if(this.filterGains.length == 0){
                    this.filterGains.push( this.ctx.createBiquadFilter() );
                    this.filterGains[0].type = "peaking";
                    this.setGainsIn([ this.filterGains[0] ]);
                }

                while (values.length < this.filterGains.length) {
                    const lastIndex = this.filterGains.length - 1;
                    this.filterGains[lastIndex - 1].disconnect(this.filterGains[lastIndex]);
                    this.setGainsOut([this.filterGains[lastIndex - 1]]);
                    this.filterGains.pop();
                }

                while (values.length > this.filterGains.length){
                    const lastIndex = this.filterGains.length - 1;
                    this.filterGains.push( this.ctx.createBiquadFilter() );
                    this.filterGains[lastIndex + 1].type = "peaking"; // peaking
                    this.setGainsOut([ this.filterGains[lastIndex + 1] ]);
                    this.filterGains[lastIndex].connect(this.filterGains[lastIndex + 1]);
                }

                const q = this.realQ * values.length * 10;
                const k = Math.pow(1000, 1/(2 * values.length));

                let freq = 20 * k;
                values.forEach((gain, index) => {
                    this.filterGains[index].frequency.value = Math.round( freq );
                    this.filterGains[index].Q.value = q;
                    this.filterGains[index].gain.value = gain;

                    freq *= k;
                    freq *= k;
                });

                console.log('filterGains', this.filterGains);
            }
        },

        mounted(){
            console.log("equalizer mounted", this.ctx, this.destination);
        },
    }
</script>
