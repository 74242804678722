export default {
    data(){
        return { config: { } };
    },

    props: {
        params: [ Object ],
    },

    watch: {
        params(newParam, oldParam){
            console.log('params')
            if(JSON.stringify(newParam) != JSON.stringify(oldParam)){
                this.applyParams(newParam)
            }
        },

        formatParams(newVal){
            this.$emit('set_params', newVal);
        }
    },

    computed: {
        formatParams(){
            return Object.assign({ }, this.config);
        }
    },

    methods: {
        applyParams(params){
            console.log('apply param');
            Object.keys(params).forEach((param) => {
                if(typeof this.config[param] != 'undefined'){
                    this.config[param] = params[param];
                }
            });
        }
    },

    mounted() {
        this.applyParams(this.params);
    }


}
